import { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Search from '@mui/icons-material/Search';
import Person from '@mui/icons-material/Person';
import useRequests from '../hooks/useRequests';
import axios from 'axios';
import Typography from '@mui/joy/Typography';
import { useNavigate } from 'react-router';
import { Alert } from '@mui/joy';
import React from 'react';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { DocumentScanner, Folder } from '@mui/icons-material';

type Status = {
  EXPERIENCE?: boolean;
  SKILLS?: boolean;
  EDUCATION?: boolean;
  ACHIEVEMENT?: [];
};

export default function UserStatusTab() {
  const [userState, setUserState] = useState<Status>();
  const [config, isTokenReady] = useRequests();
  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenReady) {
      axios
        .get('/user/status', config)
        .then((res) => {
          setUserState(res.data);
        })
        .catch((err) => {
          alert(err.response.data.description);
        });
    }
  }, [config, isTokenReady]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        borderRadius: 'sm',
        bgcolor: 'white',
        minWidth: '35%'
      }}
    >
      <InfoModal userStatus={userState}></InfoModal>
      <Tabs
        size="lg"
        aria-label="Bottom Navigation"
        sx={(theme) => ({
          borderRadius: 'xl',
          mx: 'auto',
          boxShadow: theme.shadow.sm,
          '--Tabs-gap': '8px',
          [`& .${tabClasses.root}`]: {
            boxShadow: 'none',
            borderRadius: 'lg',
            whiteSpace: 'wrap',
            transition: '0.3s',
            fontWeight: 'sm',
            flex: 1
          }
        })}
      >
        <TabList variant="plain">
          <Tab
            orientation="vertical"
            sx={{ p: 1 }}
            {...getTabConfig('EXPERIENCE', userState, 'ACHIEVEMENT')}
          >
            <Box
              display="flex"
              flexDirection="column"
              onClick={(e) => navigate('/experience')}
            >
              <ListItemDecorator>
                <HomeOutlined />
              </ListItemDecorator>
              <Typography textAlign="center" lineHeight="unset">
                Experince
              </Typography>
            </Box>
          </Tab>
          <Tab
            orientation="vertical"
            {...getTabConfig('SKILLS', userState)}
            sx={{ p: 1 }}
          >
            <Box
              display="flex"
              flexDirection="column"
              onClick={(e) => navigate('/skills')}
            >
              <ListItemDecorator>
                <FavoriteBorder />
              </ListItemDecorator>
              <Typography textAlign="center" lineHeight="unset">
                Skills
              </Typography>
            </Box>
          </Tab>
          <Tab
            orientation="vertical"
            {...getTabConfig('EDUCATION', userState)}
            sx={{ p: 1 }}
          >
            <Box
              display="flex"
              flexDirection="column"
              onClick={(e) => {
                navigate('/education');
              }}
            >
              <ListItemDecorator>
                <Search />
              </ListItemDecorator>
              <Typography textAlign="center" lineHeight="unset">
                Education
              </Typography>
            </Box>
          </Tab>
          <Tab
            orientation="vertical"
            {...getTabConfig('PERSONAL_DATA', userState)}
            sx={{ p: 1 }}
          >
            <Box
              display="flex"
              flexDirection="column"
              onClick={(e) => navigate('/personal-data')}
            >
              <ListItemDecorator>
                <Person />
              </ListItemDecorator>
              <Typography textAlign="center" lineHeight="unset">
                Peronal Data
              </Typography>
            </Box>
          </Tab>
          {/* <Tab orientation="vertical" sx={{ p: 1 }}>
            <Box
              display="flex"
              flexDirection="column"
              
              onClick={(e) => navigate('/resume-list')}
            >
              <ListItemDecorator>
                <DocumentScanner />
              </ListItemDecorator>
              <Typography textAlign="center" lineHeight="unset">
                Resume List
              </Typography>
            </Box>
          </Tab> */}
        </TabList>
      </Tabs>
    </Box>
  );
}

function getTabConfig(
  config: string,
  userState?: any,
  secondayConfig?: string
): { variant: 'plain'; color: 'info' } | { variant: 'soft'; color: 'danger' } {
  let errorField = userState ? userState![config] : false;
  if (secondayConfig && errorField) {
    errorField = userState && (userState[secondayConfig] as any[]).length == 0;
  }
  if (errorField) {
    return {
      variant: 'plain',
      color: 'info'
    };
  } else {
    return {
      variant: 'soft',
      color: 'danger'
    };
  }
}

function InfoModal(props: { userStatus?: Status }) {
  const { userStatus } = props;
  if (
    !userStatus ||
    (!Object.values(userStatus).includes(false) &&
      userStatus.ACHIEVEMENT?.length == 0)
  ) {
    return null;
  }
  return (
    <Alert
      key={'Warning'}
      sx={{ alignItems: 'flex-start' }}
      startDecorator={React.cloneElement(<TipsAndUpdatesIcon />, {
        sx: { mt: '4px', mx: '4px' },
        fontSize: 'xl2'
      })}
      variant="soft"
      color={'warning'}
    >
      <div>
        <div style={{ marginBottom: '3px' }}>
          <Typography fontWeight="lg" fontSize="lg">
            Tips
          </Typography>
          <Typography fontWeight="md">
            Please fill following section(s) to help us to make a perfect resume
            for you.
          </Typography>
          <Typography fontWeight="md">
            Not filling required data may result in errors
          </Typography>
        </div>
        {Object.entries(props.userStatus || {})
          .flatMap((e) => {
            if (e[0] == 'EDUCATION' && e[1] == false) {
              return 'Education';
            }
            if (e[0] == 'SKILLS' && e[1] == false) {
              return 'Skills';
            }
            if (e[0] == 'EXPERIENCE' && e[1] == false) {
              return 'Experience';
            }
            if (e[0] == 'ACHIEVEMENT' && (e[1] as any[]).length != 0) {
              return (
                <>
                  Acheivements section for experience at{' '}
                  {(e[1] as any[]).map((experience) => (
                    <a href={`/achievements/${experience.id}`}>
                      {experience.company}
                    </a>
                  ))}
                </>
              );
            }
            if (e[0] == 'PERSONAL_DATA' && e[1] == false) {
              return 'Personal Data';
            }
            return undefined;
          })
          .map((text, i) => {
            if (!text) return null;
            return (
              <Typography
                fontSize="md"
                sx={{ opacity: 0.8 }}
                key={text.toString()}
              >
                {text}
              </Typography>
            );
          })}
      </div>
    </Alert>
  );
}
