import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { Experience } from '../types/Experience';
import Typography from '@mui/joy/Typography';
import Select from '@mui/material/Select';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Theme, useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { Avatar } from '@mui/material/index';
import useRequests from '../hooks/useRequests';
import { Button, Card, Modal, ModalClose, Sheet, Textarea } from '@mui/joy';
import { Skill } from '../skills/Skills';

type Acheivement = {
  id: string;
  experienceId: string;
  description: string;
  skills: Skill[];
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

type Form = {
  skills: Skill[];
  description?: string;
  experienceId?: string;
};

export function Acheivements(props: {}) {
  const { experienceId } = useParams();
  const defaultForm = { skills: [], experienceId };

  const [form, setForm] = React.useState<Form>(defaultForm);
  const [open, setOpen] = React.useState(false);
  const [achievements, setAchievements] = useState<Acheivement[]>([]);
  const [experience, setExperience] = useState<Experience | undefined>();
  const [skills, setSkills] = useState<Skill[]>([]);
  const theme = useTheme();
  const [config, isTokenReady] = useRequests();

  useEffect(() => {
    if (isTokenReady) {
      axios
        .get('/achievements/' + experienceId, config)
        .then((res) => {
          setAchievements(res.data.second);
          setExperience(res.data.first);
        })
        .catch((err) => alert(err.response.data.description));
    }
  }, [experienceId, config, isTokenReady]);

  useEffect(() => {
    if (isTokenReady) {
      axios.get('/skills', config).then((res) => {
        // const skills: Skill[] = [];
        // Object.values(res.data)
        //   .flatMap((s: any) => [...s])
        //   .forEach((s) => skills.push(s as any));
        setSkills(res.data);
      });
    }
  }, [config, isTokenReady]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setForm(defaultForm);
  };
  const handleSave = () => {
    setOpen(false);
    axios
      .post('/achievements/create/' + experience?.id, form, config)
      .then((res) => {
        setAchievements(res.data);
        setForm(defaultForm);
      })
      .catch((err) => {
        alert(err.response.data.description);
      });
  };

  const handleChange = useCallback((event: any) => {
    const {
      target: { value }
    } = event;
    const newSkills = value.map((selectedSkill: string) =>
      skills.find((skill) => skill.name == selectedSkill)
    );
    setForm((prev) => ({ ...prev, skills: newSkills }));
  }, [setForm, skills]);

  function getStyles(skill: Skill, skills: Skill[], theme: Theme) {
    return {
      fontWeight:
        skills.indexOf(skill) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  }

  const onChangeHandler = (e: any) =>
    setForm((f) => ({ ...f, [e.target.name]: e.target.value }));
  const deleteAcheivement = (achievement: Acheivement) => {
    axios
      .delete(`/achievements/${experience?.id}/${achievement.id}`, config)
      .then((res) => setAchievements(res.data))
      .catch((err) => alert(err.response.data.description));
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2
        }}
      >
        <Typography level="h1">Achievements</Typography>
        <Card variant="soft" color="info" sx={{ maxWidth: 400, margin: '1%' }}>
          <Typography level="h3">{experience?.position}</Typography>
          <Typography level="h4" fontSize="xl" sx={{ mb: 0.5 }}>
            {experience?.company}, {experience?.location}
          </Typography>
          <Typography level="body1">
            {experience?.startMonth}/{experience?.startYear} -{' '}
            {experience?.present === true
              ? 'Present'
              : `${experience?.endMonth}/${experience?.endYear}`}
          </Typography>
          <Typography level="body1"></Typography>
        </Card>
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          sx={{ margin: '1%' }}
        >
          Add Achievements
        </Button>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 500,
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg'
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: 'calc(-1/4 * var(--IconButton-size))',
                right: 'calc(-1/4 * var(--IconButton-size))',
                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                borderRadius: '50%',
                bgcolor: 'background.surface'
              }}
            />
            <div>
              <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
              >
                Add An Achievement
              </Typography>
              <Typography
                id="modal-title-h4"
                level="body1"
                textColor="inherit"
                fontWeight="sm"
                mb={1}
              >
                This will help us to generate a better resume tailored for a
                specific job description using AI.
              </Typography>
            </div>
            <div style={{ marginBottom: '20px' }}>
              <Typography level="h6">Examples:</Typography>
              <Typography level="body1">
                ● Led development of two separate third party interface
                development projects for two of our biggest clients with Google
                and Apple, which were completed on time with 60% cost reduction.
                I build the system using Scala and Spring Framework.
              </Typography>
              <Typography level="body1">
                ● Automated error handling process of our restful API services
                that reduced one of our clients’ support ticket rate by 70%.
                Used Kotlin and PostgreSQL, deploying on an AWS stack.
              </Typography>
            </div>
            <Textarea
              autoFocus
              // margin="dense"
              id="description"
              placeholder="Description "
              // fullWidth
              variant="outlined"
              name="description"
              onChange={onChangeHandler}
              minRows={10}
            />
            <InputLabel id="demo-multiple-chip-label">
              Please enter skills envolved in the acheivement
            </InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={form.skills.map((s) => s.name)}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              sx={{ width: '100%' }}
              renderValue={(selected: any[]) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} color="primary" />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {skills.map((skill) => (
                <MenuItem
                  key={skill.id}
                  value={skill.name}
                  style={getStyles(skill, form.skills, theme)}
                >
                  {skill.name}
                </MenuItem>
              ))}
            </Select>
            <Box
              sx={{
                mt: 1,
                display: 'flex',
                gap: 1,
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                  justifyContent: 'center'
                }
              }}
            >
              <Button variant="outlined" color="neutral" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="solid" color="neutral" onClick={handleSave}>
                Submit
              </Button>
            </Box>
          </Sheet>
        </Modal>

        {achievements?.length > 0 && (
          <TableContainer
            component={Paper}
            sx={{ padding: '2%', width: '90%' }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography level="h6">Description</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography level="h6">Skills</Typography>
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {achievements.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.description}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.skills.map((value) => (
                        <Chip
                          key={value.id}
                          label={value.name}
                          color="primary"
                          sx={{ marginBottom: 0.5, marginRight: 0.5 }}
                          avatar={
                            <Avatar>
                              {value.name.charAt(0).toUpperCase()}
                            </Avatar>
                          }
                        />
                      ))}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button onClick={() => deleteAcheivement(row)}>
                        <DeleteTwoToneIcon color="error" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {achievements.length == 0 && <Typography> Empty</Typography>}
      </div>
    </>
  );
}
